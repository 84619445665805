import React from "react";
import { FormattedMessage } from "react-intl";

function Option({ optionData, setSelectedOption, option, language }) {
  let available = optionData.available;
  let active = optionData.selected;
  let title = eval("optionData.title_" + language);
  let description = eval("optionData.description_" + language);

  if (!available) {
    description = (
      <FormattedMessage
        id="systems.not-available"
        defaultMessage="This option is not available in this combination. Please change your selection in other tabs to activate this option."
      />
    );
  }

  return (
    <div
      onClick={available ? () => setSelectedOption(option) : undefined}
      className={`card ${active ? "card-selected" : ""} ${
        available ? "card-active" : "card-disabled"
      } mb-3`}
    >
      <div className="d-flex flex-row">
        <div className="card-img-wrapper">
          <img
            src={`/optionImages/${
              optionData.image ? optionData.image : "default.png"
            }`}
            className="card-img"
            alt={optionData.title}
          />
        </div>

        <div className="card-body">
          <h4 className="card-title">{title}</h4>
          <p className="card-text">{description}</p>
        </div>
      </div>
    </div>
  );
}

export default Option;
