import { useRouteError } from "react-router-dom";
import Header from "../components/Header/Header";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <>
      <Header />
      <div id="error-page">
        <div class="container">
          <h1>Ooops!</h1>
          <p class="lead">Sorry, an unexpected error has occurred.</p>
          <p>
            The error message was <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </div>
    </>
  );
}
