import { Bug } from "react-bootstrap-icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import connection from "./connection.svg";

function Error() {
  return (
    <div className="text-center p-5">
      <h1 className="mb-5">
        <FormattedMessage id="error.heading" defaultMessage="Don't Panic!" />
      </h1>

      <div className="row">
        <div className="col-lg-6 offset-lg-3">
          <img
            src={connection}
            className="img-fluid p-3 mb-5"
            alt="Lost Connection"
          />

          <p className="lead">
            <FormattedMessage
              id="error.loading-data"
              defaultMessage="An error occurred while loading the data. Please try again later."
            />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Error;
