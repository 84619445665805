import { FormattedMessage } from "react-intl";
import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TwoDimensional from "./two-dimensional";
import ThreeDimensional from "./three-dimensional";
import "./Display.scss";

function Display({ product, apikey, backend }) {
  const [tabKey, setTabKey] = useState("model");

  const tabs = {
    foto: {
      title: <FormattedMessage id="display.image" defaultMessage="Image" />,
      content: <TwoDimensional product={product} />,
    },
    model: {
      title: <FormattedMessage id="display.model" defaultMessage="3D" />,
      content: (
        <ThreeDimensional product={product} apikey={apikey} backend={backend} />
      ),
    },
  };

  return (
    <Tabs
      activeKey={tabKey}
      mountOnEnter={true}
      onSelect={(k) => setTabKey(k)}
      id="display-tabs"
      className="justify-content-end mb-4"
    >
      {Object.entries(tabs).map((tab, index) => (
        <Tab
          eventKey={tab[0]}
          title={tab[1].title}
          tabClassName="ms-2"
          key={index}
        >
          {tab[1].content}
        </Tab>
      ))}
    </Tabs>
  );
}

export default Display;
