import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { toast } from "react-toastify";
import Error from "./Error/Error";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

function Capacity({
  apikey,
  backend,
  setSelectedCromoxCode,
  selectedCromoxCode,
}) {
  const intl = useIntl();
  const [selectedChainstrength, setSelectedChainstrength] = useState();
  const [selectedLoadcapacity, setSelectedLoadcapacity] = useState();
  const [notify, setNotify] = useState(false);
  const { register, handleSubmit } = useForm();

  let fetchUrl =
    backend +
    "/cromox/v1/capacity?key=" +
    apikey +
    "&code=" +
    selectedCromoxCode +
    "&all=True";

  if (selectedChainstrength && notify) {
    fetchUrl = fetchUrl + "&d=" + selectedChainstrength;
  }

  if (selectedLoadcapacity && notify) {
    fetchUrl = fetchUrl + "&wll=" + selectedLoadcapacity;
  }

  let color_mode = document.documentElement.getAttribute(
    "data-bs-theme",
    "light"
  );

  const { data, error } = useSWR(fetchUrl, fetcher, {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data) {
      setSelectedCromoxCode(data.cromox_code);
      setSelectedChainstrength(data.selected_d);
      setSelectedLoadcapacity(data.selected_wll);

      if (notify) {
        toast.success(
          intl.formatMessage({
            id: "message.input-accepted",
            defaultMessage: "Your input has been successfully accepted.",
          }),
          { theme: color_mode }
        );

        if (data.notify_user) {
          toast.info(
            intl.formatMessage({
              id: "capacity.length-changed",
              defaultMessage:
                "The length of the chain has been adjusted based on your selection.",
            }),
            { theme: color_mode }
          );
        }
        setNotify(false);
      }

      if (!data.wll_valid) {
        toast.error(
          intl.formatMessage({
            id: "capacity.payload-not-possible",
            defaultMessage: "The payload entered is not possible.",
          }),
          { theme: color_mode }
        );
      }
    }
  }, [data]);

  function handleChainStrengthChange(event) {
    setNotify(true);
    setSelectedLoadcapacity();
    setSelectedChainstrength(event.target.value);
  }

  const onLoadCapacitySubmit = (data) => {
    setNotify(true);
    setSelectedChainstrength();
    setSelectedLoadcapacity(data.capacity);
  };

  if (error) return <Error />;

  if (!data) {
    return;
  }

  var sortedOptions = data.options;
  sortedOptions.sort(function (a, b) {
    return a.d - b.d;
  });

  return (
    <>
      <div className="mb-4">
        <div className="card card-cromox">
          <div className="card-body">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="">
                <label htmlFor="chainstrength" className="form-label">
                  <FormattedMessage
                    id="capacity.chain-size"
                    defaultMessage="Chain size"
                    description="Label for chain size in capacity"
                  />
                </label>
                <select
                  name="chainstrength"
                  className="form-select mb-2"
                  value={selectedChainstrength}
                  onChange={(e) => {
                    handleChainStrengthChange(e);
                  }}
                >
                  {Object.keys(sortedOptions).map((optionData, index) => (
                    <option
                      value={data.options[optionData].id}
                      key={data.options[optionData].id}
                      disabled={!data.options[optionData].available}
                    >
                      {data.options[optionData].d}{" "}
                      {data.options[optionData].available ? "" : "(*)"}
                    </option>
                  ))}
                </select>
                <div id="tragfaehigkeitHelp" className="form-text mb-2">
                  <FormattedMessage
                    id="capacity.chain-diameter"
                    defaultMessage="Diameter in millimeters (mm)"
                    description="Helptext for chain diameter in capacity"
                  />
                </div>
                <div className="form-text">
                  <FormattedMessage
                    id="capacity.not-available"
                    defaultMessage="(*) Disabled options are not available in this combination. Please change your selection in system and fitting to activate."
                    description="Helptext for chain diameter in capacity"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="separator px-5 py-4">
          <FormattedMessage
            id="capacity.or"
            defaultMessage="or"
            description="Divider between input options in capacity"
          />
        </div>

        <div className="card card-cromox">
          <div className="card-body">
            <form onSubmit={handleSubmit(onLoadCapacitySubmit)}>
              <div className="">
                <label htmlFor="loadcapacity" className="form-label">
                  <FormattedMessage
                    id="capacity.working-load-limit"
                    defaultMessage="Working load limit"
                    description="Label for working load limit in capacity"
                  />
                </label>

                <input
                  type="text"
                  id="capacity"
                  className="form-control mb-2"
                  defaultValue={selectedLoadcapacity}
                  {...register("capacity", {
                    required: true,
                  })}
                />

                <div id="tragfaehigkeitHelp" className="form-text mb-3">
                  <FormattedMessage
                    id="capacity.load-capacity"
                    defaultMessage="Load capacity in tons (t)"
                    description="Helptext for load capacity in capacity"
                  />
                </div>

                <button type="submit" className="btn btn-primary">
                  <FormattedMessage
                    id="button.update"
                    defaultMessage="Update"
                    description="Label for update button"
                  />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Capacity;
