import React, { useState, useEffect } from "react";
import useSWR from "swr";
import { toast } from "react-toastify";
import Error from "./Error/Error";
import { FormattedMessage } from "react-intl";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

function Length({
  apikey,
  backend,
  product,
  setSelectedCromoxCode,
  selectedCromoxCode,
}) {
  const intl = useIntl();
  const [selectedLength, setSelectedLength] = useState(product.data.length);
  const [notify, setNotify] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      length: selectedLength,
    },
  });

  let fetchUrl =
    backend + "/cromox/v1/length?key=" + apikey + "&code=" + selectedCromoxCode;

  if (selectedLength && notify) {
    fetchUrl = fetchUrl + "&length=" + selectedLength;
  }

  let color_mode = document.documentElement.getAttribute(
    "data-bs-theme",
    "light"
  );

  const { data, error } = useSWR(fetchUrl, fetcher, {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data) {
      setSelectedCromoxCode(data.cromox_code);
      setSelectedLength(data.length);
      reset({
        length: data.length,
      });
      if (notify) {
        toast.success(
          intl.formatMessage({
            id: "message.input-accepted",
            defaultMessage: "Your input has been successfully accepted.",
          }),
          { theme: color_mode }
        );
        setNotify(false);
      }
    }
  }, [data]);

  const onSubmit = (formData) => {
    setNotify(true);
    setSelectedLength(formData.length);
  };

  if (error) return <Error />;

  if (!data) {
    return;
  }

  return (
    <>
      <div className="d-flex justify-content-center mb-5">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label htmlFor="number" className="form-label">
                  <FormattedMessage
                    id="length.effective-working-length"
                    defaultMessage="Effective working length"
                    description="Label for effective working length in length"
                  />
                </label>

                <input
                  type="number"
                  id="length"
                  className="form-control"
                  {...register("length", {
                    required: true,
                    max: 20000,
                    min: 300,
                  })}
                />

                <div className="form-text mt-2">
                  <FormattedMessage
                    id="length.enter-working-length"
                    defaultMessage="Enter the effective working length in millimeters (mm)."
                    description="Helptext for effective working length in length"
                  />
                </div>

                {errors.length?.type === "required" && (
                  <div className="mt-1 text-danger form-text" role="alert">
                    Bitte geben Sie eine Länge an.
                  </div>
                )}
                {errors.length?.type === "min" && (
                  <div className="mt-1 text-danger form-text" role="alert">
                    Die minimale Nutzlänge beträgt 300 mm.
                  </div>
                )}
                {errors.length?.type === "max" && (
                  <div className="mt-1 text-danger form-text" role="alert">
                    Die maximale Nutzlänge beträgt 20000 mm.
                  </div>
                )}
              </div>

              <button type="submit" className="btn btn-primary">
                <FormattedMessage
                  id="button.update"
                  defaultMessage="Update"
                  description="Label for update button"
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Length;
