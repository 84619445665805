import React, { useRef, useEffect } from "react";
import Specs from "./Specs";
import Steps from "./Steps";
import Display from "./Display/Display";
import Error from "./Error/Error";
import useSWR from "swr";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

function Main({
  apikey,
  backend,
  language,
  setSelectedCromoxCode,
  selectedCromoxCode,
}) {
  let fetchUrl =
    backend +
    "/cromox/v1/product?key=" +
    apikey +
    "&code=" +
    selectedCromoxCode;

  const { data, error } = useSWR(fetchUrl, fetcher, {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data) {
      if (data.cromox_code !== selectedCromoxCode) {
        setSelectedCromoxCode(data.cromox_code);
      }
    }
  }, [data]);

  if (error) return <Error />;

  if (!data) {
    return;
  }

  return (
    <>
      <div id="configurator" className="row">
        <div className="col-12 col-xl-6 mb-3">
          <Steps
            apikey={apikey}
            backend={backend}
            product={data}
            language={language}
            setSelectedCromoxCode={setSelectedCromoxCode}
            selectedCromoxCode={selectedCromoxCode}
          />
        </div>

        <div className="col-12 col-xl-6 mb-3">
          <Display
            apikey={apikey}
            backend={backend}
            product={data}
            language={language}
          />
        </div>
      </div>

      <Specs product={data} language={language} />
    </>
  );
}

export default Main;
