import React, { useState, useEffect } from "react";
import Loading from "../Loading";

function TwoDimensional({ product }) {
  const ImageLoader = ({ src, fallbackSrc, alt }) => {
    const [imageSrc, setImageSrc] = useState(src);
    const [loading, setLoading] = useState(true);
    const [showPlaceholder, setShowPlaceholder] = useState(false);
    const [attempts, setAttempts] = useState(0);
    const maxAttempts = 1;

    const handleImageLoad = () => {
      setLoading(false);
      setShowPlaceholder(false);
    };

    const handleImageError = () => {
      if (attempts < maxAttempts) {
        setTimeout(() => {
          setAttempts(attempts + 1);
          setImageSrc(`${src}?attempt=${attempts + 1}`);
        }, 500);
      } else {
        setImageSrc(fallbackSrc);
        setLoading(false);
        setShowPlaceholder(false);
      }
    };

    useEffect(() => {
      setImageSrc(src);
      setLoading(true);

      const timer = setTimeout(() => {
        setShowPlaceholder(true);
      }, 100);

      return () => clearTimeout(timer);
    }, [src]);

    return (
      <div id="image-container">
        {loading && showPlaceholder && <Loading />}
        <img
          src={imageSrc}
          onLoad={handleImageLoad}
          onError={handleImageError}
          style={{ display: loading ? "none" : "block" }}
          alt={alt}
        />
      </div>
    );
  };

  const imageUrl =
    "https://cromox.systems/images/" + product.data.cromox_code + ".png";
  const fallbackImageUrl = "/default.png";

  return (
    <div id="stage-2d">
      <ImageLoader
        src={imageUrl}
        fallbackSrc={fallbackImageUrl}
        alt={product.data.cromox_code}
      />
    </div>
  );
}

export default TwoDimensional;
