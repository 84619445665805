import { Clipboard } from "react-bootstrap-icons";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import Button from "react-bootstrap/Button";
import CheckoutButton from "./CheckoutButton";
import React from "react";

function Specs({ product, language }) {
  const intl = useIntl();

  let description = eval("product.data.description_" + language);

  let color_mode = document.documentElement.getAttribute(
    "data-bs-theme",
    "light"
  );

  function copyDivText() {
    const textToCopy = document.getElementById("cromox-code").innerText;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        toast.success(
          intl.formatMessage({
            id: "specs.copied-to-clipboard",
            defaultMessage: "Copied to clipboard",
          }),
          { theme: color_mode }
        );
      })
      .catch((err) => {
        console.error("Error while copying text to clipboard: ", err);
      });
  }

  return (
    <div id="specs">
      <div className="mb-4">
        <div className="text-muted mb-1">
          <FormattedMessage
            id="specs.description"
            defaultMessage="Description"
            description="Label for description in specs"
          />
        </div>
        <div>{description}</div>
      </div>

      <div className="d-flex flex-wrap">
        <div className="me-5 mb-3">
          <div className="text-muted mb-1">
            <FormattedMessage
              id="specs.system"
              defaultMessage="System"
              description="Label for system in specs"
            />
          </div>
          <div>{product.data.system}</div>
        </div>

        <div className="me-5 mb-3">
          <div className="text-muted mb-1">
            <FormattedMessage
              id="specs.fitting"
              defaultMessage="End fitting"
              description="Label for fitting in specs"
            />
          </div>
          <div>{product.data.fitting}</div>
        </div>

        <div className="me-5 mb-3">
          <div className="text-muted mb-1">
            <FormattedMessage
              id="specs.strings"
              defaultMessage="Number of legs"
              description="Label for strings in specs"
            />
          </div>
          <div>{product.data.strings}</div>
        </div>

        <div className="me-5 mb-3">
          <div className="text-muted mb-1">
            <FormattedMessage
              id="specs.diameter"
              defaultMessage="Diameter"
              description="Label for diameter in specs"
            />
          </div>
          <div>{product.data.d} mm</div>
        </div>

        <div className="me-5 mb-3">
          <div className="text-muted mb-1">
            <FormattedMessage
              id="specs.capacity"
              defaultMessage="Load capacity"
              description="Label for capacity in specs"
            />
          </div>
          <div>{product.data.wll} t</div>
        </div>

        <div className="me-5 mb-3">
          <div className="text-muted mb-1">
            <FormattedMessage
              id="specs.length"
              defaultMessage="Length"
              description="Label for length in specs"
            />
          </div>
          <div>{product.data.length} mm</div>
        </div>

        <div className="me-5 mb-3">
          <div className="text-muted mb-1">cromox® Code</div>
          <div className="copy-to-clipboard">
            <Button
              variant="link"
              onClick={copyDivText}
              title={intl.formatMessage({
                id: "specs.copy-to-clipboard",
                defaultMessage: "Copy to clipboard",
              })}
            >
              <span id="cromox-code" className="me-1">
                {product.data.cromox_code}
              </span>
              <Clipboard />
            </Button>
          </div>
        </div>

        <div className="mb-3">
          <CheckoutButton product={product} />
        </div>
      </div>
    </div>
  );
}

export default Specs;
