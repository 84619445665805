import React from "react";
import Header from "../components/Header/Header";

function Privacy() {
  return (
    <>
      <Header />
      <h1>Privacy</h1>
    </>
  );
}
export default Privacy;
