import React from "react";
import Header from "../components/Header/Header";

function Imprint() {
  return (
    <>
      <Header />
      <h1>Imprint</h1>
    </>
  );
}
export default Imprint;
