import React, { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Gallery({ backend }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  let fetchURL = backend + "/images/all";

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(fetchURL);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        let actualData = await response.json();
        setData(actualData);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [fetchURL]);

  return (
    <>
      <Header />
      <Container>
        <div id="content" className="mb-5">
          <h1 className="mb-3">Product Images</h1>
          {loading && <div>A moment please...</div>}
          {error && (
            <div>{`There is a problem fetching the post data - ${error}`}</div>
          )}
          <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-4">
            {data &&
              data.map((filename, index) => (
                <Col>
                  <Card key={index}>
                    <Card.Img
                      variant="top"
                      src={`/productImages/${filename}`}
                    />
                    <Card.Body>
                      <Card.Title>{filename}</Card.Title>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  );
}
export default Gallery;
