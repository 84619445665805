import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Strings from "./Strings";
import Capacity from "./Capacity";
import Systems from "./Systems";
import Length from "./Length";
import Fittings from "./Fittings";
import NextBackButton from "./NextBackButton";
import { FormattedMessage } from "react-intl";

function Steps({
  apikey,
  backend,
  product,
  language,
  setSelectedCromoxCode,
  selectedCromoxCode,
}) {
  const [tabKey, setTabKey] = useState("string");

  const steps = {
    string: {
      title: (
        <FormattedMessage
          id="steps.strings"
          defaultMessage="Strings"
          description="Label for strings tab"
        />
      ),
      content: (
        <>
          <Strings
            apikey={apikey}
            backend={backend}
            language={language}
            setSelectedCromoxCode={setSelectedCromoxCode}
            selectedCromoxCode={selectedCromoxCode}
          />
          <div className="d-flex justify-content-end mb-3">
            <NextBackButton
              setTabKey={setTabKey}
              next={"capacity"}
              next_title={
                <FormattedMessage
                  id="button.continue-to-capacity"
                  defaultMessage="Continue to capacity"
                  description="Continue button"
                />
              }
            />
          </div>
        </>
      ),
    },
    capacity: {
      title: (
        <FormattedMessage
          id="steps.capacity"
          defaultMessage="Capacity"
          description="Label for capacity tab"
        />
      ),
      content: (
        <>
          <Capacity
            apikey={apikey}
            backend={backend}
            language={language}
            setSelectedCromoxCode={setSelectedCromoxCode}
            selectedCromoxCode={selectedCromoxCode}
          />
          <div className="d-flex justify-content-between mb-3">
            <NextBackButton
              setTabKey={setTabKey}
              next={"string"}
              next_title={
                <FormattedMessage
                  id="button.back"
                  defaultMessage="Back"
                  description="Label für back button"
                />
              }
            />
            <NextBackButton
              setTabKey={setTabKey}
              next={"system"}
              next_title={
                <FormattedMessage
                  id="button.continue-to-system"
                  defaultMessage="Continue to system"
                  description="Continue button"
                />
              }
            />
          </div>
        </>
      ),
    },
    system: {
      title: (
        <FormattedMessage
          id="steps.system"
          defaultMessage="System"
          description="Label for system tab"
        />
      ),
      content: (
        <>
          <Systems
            apikey={apikey}
            backend={backend}
            language={language}
            setSelectedCromoxCode={setSelectedCromoxCode}
            selectedCromoxCode={selectedCromoxCode}
          />
          <div className="d-flex justify-content-between mb-3">
            <NextBackButton
              setTabKey={setTabKey}
              next={"capacity"}
              next_title={
                <FormattedMessage
                  id="button.back"
                  defaultMessage="Back"
                  description="Label für back button"
                />
              }
            />
            <NextBackButton
              setTabKey={setTabKey}
              next={"fitting"}
              next_title={
                <FormattedMessage
                  id="button.continue-to-fitting"
                  defaultMessage="Continue to fitting"
                  description="Continue button"
                />
              }
            />
          </div>
        </>
      ),
    },
    fitting: {
      title: (
        <FormattedMessage
          id="steps.fitting"
          defaultMessage="Fitting"
          description="Label for fitting tab"
        />
      ),
      content: (
        <>
          <Fittings
            apikey={apikey}
            backend={backend}
            language={language}
            setSelectedCromoxCode={setSelectedCromoxCode}
            selectedCromoxCode={selectedCromoxCode}
          />
          <div className="d-flex justify-content-between mb-3">
            <NextBackButton
              setTabKey={setTabKey}
              next={"system"}
              next_title={
                <FormattedMessage
                  id="button.back"
                  defaultMessage="Back"
                  description="Label für back button"
                />
              }
            />
            <NextBackButton
              setTabKey={setTabKey}
              next={"length"}
              next_title={
                <FormattedMessage
                  id="button.continue-to-length"
                  defaultMessage="Continue to length"
                  description="Continue button"
                />
              }
            />
          </div>
        </>
      ),
    },
    length: {
      title: (
        <FormattedMessage
          id="steps.length"
          defaultMessage="Length"
          description="Label for length tab"
        />
      ),
      content: (
        <>
          <Length
            apikey={apikey}
            backend={backend}
            product={product}
            language={language}
            setSelectedCromoxCode={setSelectedCromoxCode}
            selectedCromoxCode={selectedCromoxCode}
          />
          <div className="d-flex justify-content-start mb-3">
            <NextBackButton
              setTabKey={setTabKey}
              next={"fitting"}
              next_title={
                <FormattedMessage
                  id="button.back"
                  defaultMessage="Back"
                  description="Label für back button"
                />
              }
            />
          </div>
        </>
      ),
    },
  };

  return (
    <Tabs
      activeKey={tabKey}
      onSelect={(k) => setTabKey(k)}
      id="justify-tab-example"
      className="mb-4"
    >
      {Object.entries(steps).map((tab, index) => (
        <Tab
          eventKey={tab[0]}
          title={tab[1].title}
          tabClassName="me-2"
          key={index}
        >
          {tab[1].content}
        </Tab>
      ))}
    </Tabs>
  );
}

export default Steps;
