import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { Translate, BrightnessHigh } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import logo from "./logo-white.svg";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import React from "react";

function Header({
  backend,
  apikey,
  setSelectedCromoxCode,
  setLocale,
  language,
}) {
  const intl = useIntl();

  const setTheme = function (theme) {
    document.documentElement.setAttribute("data-bs-theme", theme);
  };

  const { register, handleSubmit, reset } = useForm();

  if (language === "de") {
    document.title = "cromox® Konfigurator";
  } else {
    document.title = "cromox® Configurator";
  }

  let color_mode = document.documentElement.getAttribute(
    "data-bs-theme",
    "light"
  );

  const onSubmit = (formData) => {
    fetch(
      backend + "/cromox/v1/product?key=" + apikey + "&code=" + formData.code
    )
      .then((response) => response.json())
      .then((json) => {
        if (json.cromox_code) {
          setSelectedCromoxCode(formData.code);
          toast.success(
            intl.formatMessage({
              id: "message.input-accepted",
              defaultMessage: "Your input has been successfully accepted.",
            }),
            { theme: color_mode }
          );
          reset();
        }
      })
      .catch((error) => {
        toast.error(
          intl.formatMessage({
            id: "message.code-invalid",
            defaultMessage: "The code you entered could not be found.",
          }),
          { theme: color_mode }
        );
        reset();
      });
  };

  function handleLanguageChange(locale) {
    window.localStorage.setItem("language", JSON.stringify(locale));
    setLocale(locale);
  }

  return (
    <header>
      <Navbar bg="dark" variant="dark" expand="lg" className="py-3 mb-5">
        <Container>
          <Navbar.Brand href={`/`} className="me-4">
            <img src={logo} alt="cromox Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto my-3 my-lg-0">
              <Nav.Link href={`/`}>
                <FormattedMessage
                  id="header.new-configuration"
                  defaultMessage="New configuration"
                  description="Header link for new configuration"
                />
              </Nav.Link>
              <Nav.Link
                href={`https://www.ketten-waelder.de/datenschutz/`}
                target="_blank"
              >
                <FormattedMessage
                  id="header.privacy"
                  defaultMessage="Privacy"
                  description="Header link to privacy statement"
                />
              </Nav.Link>
              <Nav.Link
                href={`https://www.ketten-waelder.de/impressum/`}
                target="_blank"
              >
                <FormattedMessage
                  id="header.imprint"
                  defaultMessage="Imprint"
                  description="Header link to imprint"
                />
              </Nav.Link>
            </Nav>

            <Form
              onSubmit={handleSubmit(onSubmit)}
              className="d-flex me-3 my-3 my-lg-0"
            >
              <Form.Control
                type="search"
                placeholder="cromox® Code"
                className="me-2"
                aria-label="cromox® Code"
                {...register("code", { required: true })}
              />
              <Button type="submit" variant="primary">
                <FormattedMessage id="header.show" defaultMessage="Show" />
              </Button>
            </Form>

            <NavDropdown
              title={<BrightnessHigh />}
              id="color-mode-dropdown"
              className="me-2 no-arrow text-light d-inline-block d-lg-block"
            >
              <NavDropdown.Item href="#" onClick={() => setTheme("auto")}>
                <FormattedMessage id="header.auto" defaultMessage="Auto" />
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={() => setTheme("light")}>
                <FormattedMessage id="header.bright" defaultMessage="Bright" />
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={() => setTheme("dark")}>
                <FormattedMessage id="header.dark" defaultMessage="Dark" />
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={<Translate />}
              id="language-dropdown"
              className="no-arrow text-light d-inline-block d-lg-block"
            >
              <NavDropdown.Item
                href="#"
                onClick={() => handleLanguageChange("de-DE")}
              >
                <FormattedMessage id="header.german" defaultMessage="German" />
              </NavDropdown.Item>
              <NavDropdown.Item
                href="#"
                onClick={() => handleLanguageChange("en-US")}
              >
                <FormattedMessage
                  id="header.english"
                  defaultMessage="English"
                />
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
export default Header;
