import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import Configurator from "./pages/Configurator";
import Imprint from "./pages/Imprint";
import Privacy from "./pages/Privacy";
import Gallery from "./pages/Gallery";
import ErrorPage from "./pages/Error";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Configurator
        apikey={process.env.REACT_APP_API_KEY}
        backend={process.env.REACT_APP_BACKEND_URL}
      />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/imprint",
    element: <Imprint />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/products",
    element: (
      <Gallery
        apikey={process.env.REACT_APP_API_KEY}
        backend={process.env.REACT_APP_BACKEND_URL}
      />
    ),
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
