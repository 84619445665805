import React from "react";
import Button from "react-bootstrap/Button";

function NextBackButton({ setTabKey, next, next_title, back }) {
  return (
    <Button variant="outline-primary" onClick={() => setTabKey(next)}>
      {next_title}
    </Button>
  );
}

export default NextBackButton;
