import React from "react";
import logo from "./logo.svg";
import mail from "./icons/mail.svg";
import facebook from "./icons/facebook.svg";
import instagram from "./icons/instagram.svg";
import youtube from "./icons/youtube.svg";
import linkedin from "./icons/linkedin.svg";

function Footer() {
  return (
    <>
      <footer className="py-5 bg-secondary">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo">
              <img src={logo} alt="cromox Logo" />
            </div>

            <ul className="list-unstyled d-flex">
              <li className="ms-3">
                <a
                  className="link-light"
                  href="mailto:info@ketten-waelder.de"
                  target="_blank"
                  rel="noreferrer"
                  title="E-Mail"
                >
                  <img src={mail} alt="Mail Icon" />
                </a>
              </li>

              <li className="ms-3">
                <a
                  className="link-light"
                  href="https://www.facebook.com/cromox.chains"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} alt="Facebook Icon" />
                </a>
              </li>

              <li className="ms-3">
                <a
                  className="link-light"
                  href="https://www.instagram.com/cromox.chains/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="Instagram Icon" />
                </a>
              </li>

              <li className="ms-3">
                <a
                  className="link-light"
                  href="https://www.youtube.com/channel/UCk0upNIJNNsRImo-5EbO98g/about"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={youtube} alt="Youtube Icon" />
                </a>
              </li>

              <li className="ms-3">
                <a
                  className="link-light"
                  href="https://www.linkedin.com/company/ketten-w%C3%A4lder-gmbh"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedin} alt="Linkedin Icon" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <div className="my-3 text-muted text-center">
        Version {process.env.REACT_APP_VERSION}
      </div>
    </>
  );
}
export default Footer;
