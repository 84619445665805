import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Main from "../components/Configurator/Main";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import { IntlProvider } from "react-intl";
import English from "../lang/en.json";
import German from "../lang/de.json";

function Configurator({ apikey, backend }) {
  const [searchParams] = useSearchParams();
  const [selectedCromoxCode, setSelectedCromoxCode] = useState(
    searchParams.get("code") || "JBSK00RS"
  );
  const defaultLanguage = "en-US";

  const messages = {
    de: {
      locale: "de-DE",
      translations: German,
    },
    en: {
      locale: "en-US",
      translations: English,
    },
  };

  function getUrlLanguage() {
    let language = searchParams.get("language");
    if (language in messages) {
      window.localStorage.setItem(
        "language",
        JSON.stringify(messages[language]["locale"])
      );
      return messages[language]["locale"];
    }
  }

  function getLocalStorageLanguage() {
    let language = JSON.parse(window.localStorage.getItem("language"));
    if (language && language.split(/[-_]/)[0] in messages) {
      return language;
    } else {
      return defaultLanguage;
    }
  }

  const [locale, setLocale] = useState(
    getUrlLanguage() || getLocalStorageLanguage() || navigator.language
  );

  const language = locale.split(/[-_]/)[0];

  return (
    <IntlProvider
      locale={locale}
      messages={messages[language]["translations"]}
      onError={(error) => {
        /* Handle missing message... */
      }}
    >
      <ToastContainer />
      <Header
        apikey={apikey}
        backend={backend}
        setSelectedCromoxCode={setSelectedCromoxCode}
        setLocale={setLocale}
        language={language}
      />
      <Container>
        <div id="content" className="mb-5">
          <Main
            apikey={apikey}
            backend={backend}
            language={language}
            selectedCromoxCode={selectedCromoxCode}
            setSelectedCromoxCode={setSelectedCromoxCode}
          />
        </div>
      </Container>
      <Footer />
    </IntlProvider>
  );
}

export default Configurator;
