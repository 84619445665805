import React, { useState, useEffect } from "react";
import useSWR from "swr";
import Option from "./Option";
import Error from "./Error/Error";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

function Strings({
  apikey,
  backend,
  language,
  setSelectedCromoxCode,
  selectedCromoxCode,
}) {
  const [selectedOption, setSelectedOption] = useState();

  let fetchUrl =
    backend +
    "/cromox/v1/strings?key=" +
    apikey +
    "&code=" +
    selectedCromoxCode;

  if (selectedOption) {
    fetchUrl = fetchUrl + "&option=" + selectedOption;
  }

  const { data, error } = useSWR(fetchUrl, fetcher, {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (data) {
      setSelectedCromoxCode(data.cromox_code);
    }
  }, [data]);

  if (error) return <Error />;

  if (!data) {
    return;
  }

  return (
    <div>
      {Object.keys(data.options).map((optionData, index) => (
        <Option
          key={data.options[optionData].id}
          optionData={data.options[optionData]}
          setSelectedOption={setSelectedOption}
          option={data.options[index].id}
          language={language}
        />
      ))}
    </div>
  );
}

export default Strings;
