import React from "react";
import { FormattedMessage } from "react-intl";

function CheckoutButton({ product }) {
  var length = product.data.length / 1000;

  let articleIMG = "?image=" + product.data.cromox_code + ".png";

  let articleURL =
    process.env.REACT_APP_SHOP_URL +
    "/detail/" +
    product.data.id +
    articleIMG +
    "#ncfg=" +
    length;

  return (
    <a
      href={articleURL}
      className="btn btn-primary"
      target="_blank"
      rel="noreferrer"
    >
      <FormattedMessage
        id="button.checkout"
        defaultMessage="Buy this article"
        description="Label for checkout button in specs"
      />
    </a>
  );
}

export default CheckoutButton;
